import React from 'react';
import Scroll from './Scroll';
import config from '../../config';

export default function Header({ title, heading, avatar }) {
  return (
    <section id="header">
      <header>
        <h1>{config.authorName}</h1>
        <p>
        {config.headingOne} |{' '}
        {config.headingFour} |{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={'https://www.scaledagile.com'}
          >
            <i>{config.headingTwoPartOne}</i>
          </a>
          <sup>&reg;</sup>
          {config.headingTwoPartTwo} |{' '}
          {config.headingThree}
        </p>
      </header>
      <footer>
        <Scroll type="id" element="banner">
          <a href="#banner" className="button style2 ">
            Prologue
          </a>
        </Scroll>
      </footer>
    </section>
  );
}
